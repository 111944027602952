import {PageProps, navigate} from 'gatsby'
import React, {useCallback} from 'react'
import RepairContent from "../../components/RepairContent";
import {Helmet} from "react-helmet"
import RepairShopMap from "../../components/RepairShopMap";
import shopIcon from '../../images/shop_Icon.png';
import GarantiesReco from "./GarantiesReco";
import styled from "styled-components";
import RepairShopSelect from "../../components/RepairShopSelect";


const Title = styled.h1`
    padding: 0 10px;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    line-height: 24px;
    color: #0F4C81;
    font-weight: 700;
    margin: 0 0 50px 0;
    text-align: center;
    @media (min-width: ${({theme}) => theme.breakpoints.md}) {

    }
`

const SubText = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 20px;
    margin: 50px 0;
    padding: 0 10px;
    text-align: center;
    @media (min-width: ${({theme}) => theme.breakpoints.md}) {

    }
`


const ReconditionnePage: React.FC<PageProps> = () => {

    const pathBuilder = useCallback((shopId: string) => {
        if (typeof window !== 'undefined') {
            const segments = window.location.pathname.split('/')
            segments.pop()

            return shopId;
        }
        return '';
    }, [])

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const shopId = e.target.value
            const path = pathBuilder(shopId)
            navigate(path)
        },
        [pathBuilder]
    )


    return (
        <>
            <Helmet>
                <title>Choisissez votre boutique pour acheter un téléphone reconditionné - Offres et Comparatifs</title>
                <meta
                    name="description"
                    content="Trouvez la meilleure boutique pour acheter des téléphones reconditionnés Apple, Samsung, Xiaomi et Huawei. Comparez les offres et économisez."
                />
            </Helmet>

            <br />
            <GarantiesReco />

            <SubText>
                Save vous propose une sélection de smartphones reconditionnés dans nos magasins. <br />
                Réservez votre smartphone et venez le chercher sans plus attendre dans votre magasin.
            </SubText>

            <Title>&Eacute;tape 1 : Choisissez un magasin pour découvrir les catalogues de nos reconditionnés</Title>

            <RepairContent.Card
                index={<img src={shopIcon} alt="Shop Icon"/>}
                title={
                    <RepairContent.SeoHeader
                        floatingOnDesktop
                        subtitle={"Dans quel magasin souhaitez-vous acheter un reconditionné ?"}
                        title={""}
                    />
                }
            >
                <RepairShopMap
                    onChange={handleChange}
                    pathBuilder={pathBuilder}
                    brandName={''}
                />
            </RepairContent.Card>
            <br/>
            <br/>
            <br/>
            <RepairShopSelect pathBuilder={pathBuilder}/>
        </>
    )
}
export default ReconditionnePage;
