import ServiceContent from "../../components/ServiceContent";
import {StaticImage} from "gatsby-plugin-image";

import React from 'react';

class GarantiesReco extends React.Component<{}> {
    render() {

        return (
            <>
                <ServiceContent.Title>
                    <ServiceContent.PageTitleWithColor>
                        Reconditionnés
                    </ServiceContent.PageTitleWithColor>
                    {' '}par SAVE, expert de la réparation mobile
                </ServiceContent.Title>

                <ServiceContent.AttributesDiv>
                    <ServiceContent.AttributeDiv>
                        <StaticImage
                            src="../../images/SmartphoneTested.svg"
                            alt="Testés dans nos boutiques"
                            placeholder="blurred"
                            quality={100}
                            width={53}
                            height={53}
                        />

                        <ServiceContent.AttributeLabel>
                            Testés sur plus<br></br>de{' '}
                            <b>
                                50 points<br></br>de contrôle
                            </b>
                        </ServiceContent.AttributeLabel>
                    </ServiceContent.AttributeDiv>
                    <ServiceContent.AttributeDiv>
                        <StaticImage
                            src="../../images/Europe.svg"
                            alt="Réparation en Europe"
                            placeholder="blurred"
                            quality={100}
                            width={53}
                            height={53}
                        />

                        <ServiceContent.AttributeLabel>
                            Reconditionnés<br></br>en <b>Europe</b>
                        </ServiceContent.AttributeLabel>
                    </ServiceContent.AttributeDiv>
                    <ServiceContent.AttributeDiv>
                        <StaticImage
                            src="../../images/Garantie24.svg"
                            alt="Garantie 24 mois"
                            placeholder="blurred"
                            quality={100}
                            width={53}
                            height={53}
                        />

                        <ServiceContent.AttributeLabel>
                            Garantie<br></br>Save <b>24 mois</b>
                        </ServiceContent.AttributeLabel>
                    </ServiceContent.AttributeDiv>
                </ServiceContent.AttributesDiv>
            </>
        );
    }
}

export default GarantiesReco;
