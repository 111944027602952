import React from 'react'
import styled, { css } from 'styled-components'
import LinkExtended from './LinkExtended'
import { StaticImage } from 'gatsby-plugin-image'

const ServiceContentWrapper = styled.div`
  padding: 15px;
  flex: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 34px 15px 0 15px;
  }
`

const generateOrders = (length: number) => () =>
  Array.from({ length })
    .map((_, i) => i)
    .reduce((accu, val) => {
      return css`
        ${accu}
        & > *:nth-child(${val}) {
          order: ${val * 2};
        }
      `
    }, css``)
    .join('')

interface ServiceContentContainerProps {
  childrenCount?: number
}

const ServiceContentContainer = styled.div<ServiceContentContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${({ childrenCount }) => childrenCount && generateOrders(childrenCount)}
  & > *[class^='Button__'] {
    margin: 0 auto 15px auto;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin: 5px auto 25px auto;
    }
  }

  & > *[class^='AdditionalTiles__'] {
    padding: 0;
    margin: 0 -1rem 0 -1rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin: 0 -1rem 25px -1rem;
      /* order: 5; */
    }
  }
`

interface Props {
  children: React.ReactNode
}

const ServiceContent: React.FC<Props> = ({ children }) => (
  <ServiceContentWrapper>
    <ServiceContentContainer
      childrenCount={Array.isArray(children) ? children.length + 1 : undefined}
    >
      {children}
    </ServiceContentContainer>
  </ServiceContentWrapper>
)

const Title = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 25px;
  color: ${({ theme }) => theme.colors.secondary};
  margin-top: 25px;

  &:has(span) {
    text-align: center;
  }
`

const Paragraph = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 15px;
`

const List = styled.ul`
  list-style: disc;
  padding-left: 20px;
`

const PageTitle = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  color: ${({ theme }) => theme.colors.secondary};
`

const PageTitleWithColor = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`

const ServiceContainer = styled.div`
  padding: 35px 30px 0 30px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 40px;
  }
`

const AttributeDiv = styled.div`
  display: flex;
  align-items: center;
`

const AttributeLabel = styled.div`
  font-size: 20px;
  margin-left: 25px;

  & > b {
    font-weight: bold;
  }
`

const AttributesDiv = styled.div<{ isCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ isCenter }) =>
    isCenter ? 'space-evenly' : 'space-around'};
  row-gap: 30px;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    align-items: none;
  }
`

const ComparativeTitleDiv = styled.div<{ isWhite: boolean }>`
  background-color: ${({ isWhite }) => (isWhite ? 'white' : '#0F4C81')};
  color: ${({ isWhite }) => (isWhite ? '#0F4C81' : 'white')};
  border: 1px solid ${({ isWhite }) => (isWhite ? '#0F4C81' : 'white')};
  width: 198px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 14px;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: 24px;
  }
`

const ComparativeDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > div:last-child {
    & > b {
      font-weight: bold;
    }
  }
`

interface ComparativeContentProps {
  title: string
  starNumber: number
}

const ComparativeContent: React.FC<ComparativeContentProps> = ({
  starNumber,
  title,
}) => {
  const list = []

  for (let i = 0; i < starNumber; i++) {
    list.push(
      <div style={{ margin: '3px' }}>
        <StaticImage
          src="../images/save-green.png"
          alt=""
          placeholder="blurred"
          quality={100}
          height={14}
          width={14}
        />
      </div>
    )
  }
  return (
    <div>
      <label>{title}</label>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {list}
      </div>
    </div>
  )
}

interface ComparativeProps {
  children: React.ReactNode
  title: string
  isWhite: boolean
}

const ComparativeCard: React.FC<ComparativeProps> = ({
  children,
  title,
  isWhite,
}) => (
  <ComparativeDiv>
    <ComparativeTitleDiv isWhite={isWhite}>{title}</ComparativeTitleDiv>
    <Paragraph>{children}</Paragraph>
  </ComparativeDiv>
)

const Button = styled(LinkExtended)`
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;

  border-radius: 10px;
  text-decoration: none;
  font-size: 17px;
  line-height: 20px;
  transition: background-color 300ms ease;
  margin: auto;
  width: fit-content;
  padding: 5px 15px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 21px;
    padding: 10px 20px;
  }
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.md}) and (max-width: ${({ theme }) =>
      theme.breakpoints.md}) {
    font-size: 18px;
    padding: 5px 15px;
  }
`
interface SaveListProps {
    image: string;
    title: string[];
    desc: string[];
}

const SaveElement = styled.p`
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.secondary};
    padding: 10px 15px;
    align-self: center;
`

const SaveList : React.FC<SaveListProps> = ({image, title, desc}) => {
    return (
        <ul>
            {title.map((t, index) => (
                <li key={index} style={{display: "flex", minHeight: "60px"}}>
                    <img src={image} alt="picto_save" style={{padding: "0 5px", alignSelf: "center"}}/>
                    <SaveElement>
                        <b style={{fontWeight: "bold"}}>{t}</b>{desc[index]}
                    </SaveElement>
                </li>
                )
            )}
        </ul>
    )
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-bottom: 10px;
  }
`
interface ResponsiveImageProps {
    desktopSrc: string;
    mobileSrc: string;
    alt: string;
    desktopWidth: string;
}

const ResponsiveImage = styled.img<ResponsiveImageProps>`
    margin: 20px 0;
    align-self: center;
    content: url(${props => props.desktopSrc});
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        content: url(${props => props.mobileSrc});
        width: 100%;
        max-width: 400px;
    }
    @media (min-width: ${({theme}) => theme.breakpoints.md}) {
        width: ${props => props.desktopWidth};
    }
`

export default Object.assign(ServiceContent, {
  Title,
  Paragraph,
  List,
  PageTitle,
  PageTitleWithColor,
  ServiceContainer,
  AttributeDiv,
  AttributesDiv,
  AttributeLabel,
  ComparativeCard,
  ComparativeContent,
  Button,
  SaveList,
  ButtonContainer,
  ResponsiveImage,
})
